* { margin: 0; padding: 0; border: 0; text-decoration: none; }

html, body {
	height: 100%;
}

body {
	color: #000;
	background: #fff;
	font: 100 100%/1.4 -apple-system, "BlinkMacSystemFont", "Helvetica Neue", "Helvetica", "Lucida Grande", "Arial", sans-serif;
	scroll-snap-type: y proximity;
}

img {
	max-width: 100%;
}

header,
article,
footer {
	scroll-snap-align: start;
	min-height: 100%;
	background: #fff center center fixed no-repeat;
	background-size: cover;
	color: #000;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 4em 20%;
	box-sizing:border-box;
	position: relative;
	content-visibility: auto;
}
pre {
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 5px;
	white-space: normal;
	text-align: right;
	font: inherit;

	code {
		font: inherit;
		font-size: .7em;
	}
}

hr {
	height: 1px;
  width: 100%;
  margin-bottom: 1.5em;
	background: rgba(255, 255, 255, .3);
	border-bottom: 1px solid rgba(0, 0, 0, .5);
}

footer {
	height: 100%;
	padding: 0;
}

.row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	width: 100%;

	h4 {
		text-align: center;
	}

	img {
		height: 100px;
		max-height: 18vw;
		max-width: 18vw;
	}
}

.dark {
	background-color: #000;
	color: #fff;
}

h1, h2, h3, h4 {
	font-weight: 100;
	letter-spacing: .03em;
}

h1 { font-size: 2.4em; text-align: center; }
h2 { font-size: 1.5em; text-align: justify; }
h3 { font-size: 1.8em; }
h4 { font-size: 1.2em; }

h1, h2, h3, h4, p, ul {
	margin: 0 0 .5em;
}

ul {
	padding:0 0 0 1em;
}

a {
	color: inherit !important;
	text-decoration: underline;
}

.box {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	align-content: stretch;
	width: 100%;

	section {
		width: 50%;
		padding: 0 1em;
	}
}

input, textarea, button {
	box-sizing: border-box;
	border: 1px solid #000;
	width: 100%;
	font: inherit;
	padding: .25em;
}

input, textarea {
	font-size: 1.2em;
	margin-bottom: 2em;
}

input {
	line-height: 2em;
}

textarea {
	height: 7em;
	resize: vertical;
}

button {
	font-size: 2em;
}

.error {
	color: #a00;
	text-align: center;
}

.contact img.profile {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	border: 5px solid #fff;
	box-shadow: 0 0 0 1px #000;
}
.contact img[src*="svg"] {
	height: 1.5em;
	vertical-align: middle;
}

article > a[href^="#"] {
	content: ' ';
	display: block;
	position: absolute;
  left: 50%;
  bottom: 2em;
	width: 4em;
	height: 4em;
	margin: 0 0 0 -2em;
	transform: rotate(135deg);
	transform-origin: center;

	box-shadow: 5px -5px 0 -4px rgba(255, 255, 255, .75),
						  5px -5px 0 -3px rgba(0, 0, 0, .75);
  border-radius: 1.5em;
}
.dark a[href^="#"] {
	box-shadow: 5px -5px 0 -4px rgba(0, 0, 0, .75),
						  5px -5px 0 -3px rgba(255, 255, 255, .75);
}

@media (max-width: 800px) {
	header,
	article {
		padding: 2em;
	}

	h1 { font-size: 2em; }
	h2 { font-size: 1.7em; }
	h3 { font-size: 1.3em; }
	h4 { font-size: 1em; }

	.box {
		flex-direction: column;

		section {
			width: 100%;
		  padding: 0;
		}
	}

	a[href^="#"] {
		display: none;
	}
}

h1 img {
	display: none;
}

@media print {
	* {
		background: none !important;
		color: black !important;
	}

	[no-print] {
		display: none;
	}

	header,
	article,
	footer {
		min-height: auto;
		display: block;
		padding: 1em 2em 0;
	}

	h1 {
		font-size: 1.4em;
    text-align: left;

		img {
			display: inline;
			float: left;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			margin-right: 1em;
		}
	}

	header p img {
		display: none;
	}

	h2, h3, h4 { font-size: 1.2em; }

	ul {
		padding: 0;
	}
	li {
		display: inline;
	}
	li:after {
		content: ', ';
	}
	li:last-child:after {
		content: '.';
	}
	p img {
		display: block;
		margin: 0 auto;
	}

	a[href^="#"] {
		display: none !important;
	}
}
